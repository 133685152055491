import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { G_ComplainsSuggestionsViewModel } from 'src/app/viewmodels/g-settings/G_ComplainsSuggestionsViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { HttpClient } from '@angular/common/http';
import { SaleDetailViewModel } from 'src/app/viewmodels/g-sale/SaleDetailViewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-sale/G_TagsViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';

@Component({
  selector: 'app-direct-sale-invoice',
  templateUrl: './direct-sale-invoice.component.html',
  styleUrls: ['./direct-sale-invoice.component.scss']
})
export class DirectSaleInvoiceComponent implements OnInit {
  modalRef: BsModalRef;
  disabled = true;
  @Input() public vmC: G_ComplainsSuggestionsViewModel;
  @Input() public vmD: DefineAccountviewModel;
  @Input() public vmP: GPackagesViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  public component_Title = 'Direct Sale Invoice';
  public vehicleDetail: any = [];
  public complaintsType: any = [];
  public ccomplaintsType: 'complaintsType';
  public packageTypes: any = [];
  public cvehicleDetail = 'vehicleDetail';
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public saleDetailVM: any = [];
  public services: any = [];
  public selectedServices: any = [];
  public cpackageTypes = 'packageTypes';
  public items: any = [];
  public brands: any = [];
  public selectedBrands: any = [];
  public models: any = [];
  public selectedModels: any = [];
  public citems: any = 'items';
  public cbrands: any = 'brands';
  public cmodels: any = 'models';
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectedPacakgeTypes: any = [];
  public tags: any = [];
  public selectedTagList: any;
  public selectedsaleDetail: any[];
  public saleDetail: any[];
  public csaleDetail: any[];
  public thumbnail: any;
  public ComName: any;
  public Address: any;
  public contect: any;
  public calingUrl: string;
  public urlId: number;
  public client: any;
  public tagsJoin: string = "";
  public partsAmount:number = 0;
  public serviceAmount:number = 0;
  public totalVat:number = 0;
  public packageAmount:number = 0;
  public discountAmount:number = 0;

  constructor(private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private _localStorageService: LocalStorageService) {

  }

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.getimage();
    debugger
    this.vm = new G_SaleMasterViewModel();
    //
    this.saleDetailVM = new SaleDetailViewModel();
    //console.log(this.vm);
    this.calingUrl = this._router.url;
    this.route.queryParams.subscribe(params => {
      this.urlId = params['id'];
    });
    this._jq.showSpinner(true);
    this._uow.saleService.GetById(this.urlId).subscribe((res: any) =>{
      console.log(res);
      debugger
      this.vm = res;
      this.tagsJoin = this.vm.SalesTagsList.map(tag => tag.Name).join(", ");

      this.vm.SpareParts.forEach(x => {
        this.partsAmount += x.TotalAmount;
        this.totalVat += x.VatAmount;
        if (x.DiscountAmount > 0) {
          this.discountAmount += (x.DiscountAmount * x.Quantity);
        }
      });

      this.vm.Services.forEach(x => {
        this.serviceAmount += x.TotalAmount;
        this.totalVat += x.VatAmount;
        if (x.DiscountAmount > 0) {
          this.discountAmount += (x.DiscountAmount * x.Quantity);
        }
      });

      this.packageAmount = this.partsAmount +this.totalVat + this.serviceAmount;
      console.log(res);
      this._jq.showSpinner();
    },(err)=>{
      console.log(err);
      this._jq.showSpinner();
    })

  }


  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }

  openModalLg(template: TemplateRef<any>, itemtype?) {

    this.openModal(template);
    this.modalRef.setClass('modal-lg');
  }

  getTags() {
    this._uow.tagsService.GetList().subscribe((res: any) => { this.tags = res; }, (err) => console.log(err));
  }

  onButtonClick() {
    this._jq.showSpinner(true);
    this._router.navigate(['/direct-sale-list'])
  }

   // Tags
  onSelectTags(e) {
    if (this.vm.SalesTags == undefined || this.vm.SalesTags.length == 0) {
      this.vm.SalesTags = [];
    }
    this._uow.logger.log(e);
    let item = this.tags.find(x => x.Id === e.Id);
    if (item !== undefined) {
      let i = new G_TagsViewModel();
      i.Name = item.Name;
      // i.Tags_Type.Id = item.Id;
      // i.Tags_Type.Name = item.Name;
      i.FK_Tag_Id = item.Id;
      this.vm.SalesTags.push(i);
      // let a = new G_SaleMasterViewModel();
      // a.Id = item.Id;
      // a.Name = item.Name;
      // this.vm.SalesTags.push(i);

      }

  }

  getimage()
  {
    this._uow.settingservice.GetByImage().subscribe((baseImage: any) =>{
      console.log(baseImage);
      if (baseImage != undefined &&  baseImage!==null) {
        if (baseImage.Attachment != undefined &&  baseImage.Attachment !==null) {

          this.thumbnail = "https://garagedesks.s3.eu-central-1.amazonaws.com/" + baseImage.Attachment;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }else{
          this.thumbnail = baseImage.ComName;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }
      }

    },(err)=>{
      console.log(err);
    })
  }

  onDeSelectTags(e) {
    let i = this.vm.SalesTags.find(x => x.Id === e.Id);
    if (i !== undefined) {
      this.vm.SalesTags.splice(this.vm.SalesTags.indexOf(i), 1);
    }

  }

  onItemSubmitted(e) {
    if (e == true) {
      //this.getList();
      this.getTags();
      this._jq.showSpinner(false);
    }
  }

  getSpareParts(e){
    this._uow.logger.log(e);

    let item = this.saleDetail.find(x => x.Id === e.id);
    if (item !== undefined) {
      // let a =  new GPackageItemViewModel();
      // a.Quantity = 1;
      // a.Fk_G_SSItemDefining_Id = e;
      // a.DescountPercentage = 0;
      // a.SalePrice = item.SalePrice;
      // a.Amount = 0;
      // a.Amount = item.SalePrice;
      item.SaleDetails.forEach(x => {

        if (x.FK_G_Item_Type_Id.Id === 18) {
          let selectedparts = this.spareParts.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);

          if (selectedparts !== undefined) {
            let i = new SaleDetailViewModel();
            i.Quantity = 1;
            i.FK_G_Item_Id = new ItemViewModel(selectedparts.Id, selectedparts.Name);
            i.FK_G_Item_Type_Id = new ItemViewModel(selectedparts.Id, selectedparts.Name);
            // i.AvalibelQuantity = item.CurrentStock;
            // i.Quantity = item.CurrentStock;
            i.Item_Code = selectedparts.Code;
            i.Item_Name = item.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.Item_Type = selectedparts.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            //this.vm.SaleDetails.push(i);
            let a =  new GPackageItemViewModel();
            a.Quantity = 1;
            a.Fk_G_SSItemDefining_Id = e;
            a.DescountPercentage = x.DescountPercentage;
            a.SalePrice = x.SalePrice;
            a.Amount = 0;
            a.Amount = x.SalePrice;
            a.DiscountAmount =  0;
            //this.vmP.SpareParts.push(a);
          }

        }

        if (x.FK_G_Item_Type_Id.Id === 19) {
          let selectedservices = this.services.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);
          if (selectedservices !== undefined) {
            let i = new SaleDetailViewModel();

            i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
            i.FK_G_Item_Type_Id = new ItemViewModel(selectedservices.Id, selectedservices.Name);
            //i.AvalibelQuantity = item.CurrentStock;
            i.Quantity = 1;
            i.Item_Code = selectedservices.Code;
            i.Item_Name = item.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.Item_Type = selectedservices.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            //this.vm.SaleDetails.push(i);
            let a =  new GPackageItemViewModel();
            a.Quantity = 1;
            a.Fk_G_SSItemDefining_Id = e;
            a.DescountPercentage = x.DescountPercentage.toFixed(2);
            a.SalePrice = x.SalePrice;
            a.Amount = 0;
            a.Amount = x.SalePrice;
            //this.vmP.Services.push(a);
          }

        }
      });
       //this.vmP.SpareParts.push(a);
      }
  }


}
