import {
  Component,
  OnInit,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { JqHelper } from "src/app/common/jq-helper";
import { UnitOfWorkService } from "src/app/services/api/uow/unit-of-work.service";
import { GVechileViewModel } from "src/app/viewmodels/g-vehicle/GVechileViewModel";
import { ItemViewModel } from "src/app/viewmodels/ItemViewModel";
import { DefineAccountviewModel } from "src/app/viewmodels/g-customer/DefineAccountviewModel";
import { Router } from "@angular/router";
import { G_TagsViewModel } from "src/app/viewmodels/g-settings/G_TagsViewModel";
import { G_SaleMasterViewModel } from "src/app/viewmodels/g-sale/G_SaleMasterViewModel";
import Swal from "sweetalert2";
import { GItemDefiningViewModel } from "src/app/viewmodels/g-item/GItemDefiningViewModel";
import { SaleDetailViewModel } from "src/app/viewmodels/g-sale/SaleDetailViewModel";
import { GPackageItemViewModel } from "src/app/viewmodels/g-packages/GPackageItemViewModel";
import { GPackagesViewModel } from "src/app/viewmodels/g-packages/GPackagesViewModel";
import { G_PackagesTypeViewModel } from "src/app/viewmodels/g-packages/G_PackagesTypeViewModel";
import { HttpClient } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { timer } from "rxjs";
import { E_G_EPSStatusType } from "src/app/viewmodels/g-purchase/E_G_EPSStatusType";
import { NgxSpinnerService } from "ngx-spinner";
import { G_Warehouse_Viewmodel } from "src/app/viewmodels/g-settings/G_Warehouse_Viewmodel";
import { LocalStorageService } from "src/app/services/common/local-storage.service";
@Component({
  selector: "app-direct-sale",
  templateUrl: "./direct-sale.component.html",
  styleUrls: ["./direct-sale.component.scss"],
})
export class DirectSaleComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  modalRefLv3: BsModalRef;
  modalRef: BsModalRef;
  public vmListItems: any = [];
  @Input() public modal_title: any;
  @Input() public vmP: DefineAccountviewModel;
  @Input() public vmp: GPackagesViewModel;
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmDetail: number = 15;
  private submitted = false;
  public component_Title = "Direct Sale";
  public vehicleDetail: any = [];
  public selectedTagsList: any = [];
  public cvehicleDetail = "vehicleDetail";
  public selectedvehicleDetail: any = [];
  public userAssignedWareHouses: Array<any> = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public spareParts: Array<any> = [];
  public selectedSpareParts: any = [];
  public totalExpense:number=0;
  public allowedExpense:number=0;
  bsmRef: any;
  @Output() onSubmitted = new EventEmitter<any>();
  public tags: any = [];
  public selectedTagList: any = [];
  public citems: any = "items";
  public selectedDate: any;
  public DisableSave: boolean = false;
  public EnableNext: boolean = false;
  public isPreview: boolean = false;
  public thumbnail: any;
  public ComName: any;
  public Address: any;
  public contect: any;
  public SelectedItemServices: any = [];
  public services: any = [];
  public selectedemail: any = [];
  public customerAccount: ItemViewModel;
  public tagsJoin: string = ""
  public brandsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  public modelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  public PackageSelectionDropdown: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    limitSelection: 1,
  };
  name = "CP_Email";
  id = "Id";
  checked = true;
  isdisabled = false;
  label = "Toggle On/Off";
  labelledby = "Some Other Text";

  public itemTypes = [];
  public ssItemVm = new GItemDefiningViewModel();
  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "RegistraionNumber",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "Acc_Titel",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    limitSelection: 1,
  };
  public customerDetail: any = [];
  public selectedcustomerDetail: any = [];
  public ccustomerDetail = "customerDetail";
  public selectedServices: any = [];
  public maxDate = new Date();
  scannedBarcode: string = '';
  public client: any;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    public _router: Router,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService
  ) {}

  files: File[] = [];

  onSelect(e) {
    

    this.files.push(...e.addedFiles);

    this.vm.file = this.files;
  }
  onChange(e) {
    if (this.selectedvehicleDetail.length != 0) {
      let item = this.itemVm.Fk_G_Customer_Id_Email;
      if (e == true) {
        let i = new G_SaleMasterViewModel();
        this.vm.FK_Customer_Acc_Email = new ItemViewModel(item.Id, item.Name);
        this.vm.IsEmailRequired = true;
      } else {
        this.vm.FK_Customer_Acc_Email = null;
        this.vm.IsEmailRequired = false;
      }
    } else {
      let item = this.selectedemail;
      if (e == true) {
        let i = new G_SaleMasterViewModel();
        this.vm.IsEmailRequired = true;
        this.vm.FK_Customer_Acc_Email = new ItemViewModel(
          item.Id,
          item.CP_Email
        );
      } else {
        this.vm.FK_Customer_Acc_Email = null;
        this.vm.IsEmailRequired = false;
      }
    }
  }

  onQrCodeChange(e){
    debugger;
    if(e.target.checked == true){
      this.vm.IsQRGenerate = e.target.checked;
    }
    else{
      this.vm.IsQRGenerate = false;
    }
  }
  onRemove(e) {
    console.log(e);
    this.files.splice(this.files.indexOf(e), 1);
  }

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.vmDetail = parseInt(this.client.Vat);
    this.getRegNo();
    this.getimage();
    //this.getCustomerName();
    this.getCustomers();
    this.getRequisitions();
    this.getTags();
    // this.getSpareParts();
    this.getServices();
    // if (this.vmP == undefined) {
    //   this.vmP = new DefineAccountviewModel();
    //   this._uow.customerService.GetList().subscribe((res: any) => {
    //     this.vmP = res.DtlList;
    //   }, (err) => console.log(err));
    // }
    if (
      this.itemVm.FK_Tag_List !== undefined &&
      this.itemVm.FK_Tag_List.length > 0
    ) {
      this.itemVm.FK_Tag_List.forEach((x) => {
        this.selectedTagList.push(new ItemViewModel(x.Id, x.Name));
      });
    }
    if (this.itemVm == undefined) {
      this.itemVm = new GVechileViewModel();
      this.vehicleDetail = [];
      this.itemVm.Fk_G_Customer_Discount = 0;
      this.itemVm.Fk_G_Customer_Id_Email = new ItemViewModel(0, "");
      this.selectedvehicleDetail = [];
    } else {
    }
    if (this.vm == undefined) {
      this.vm = new G_SaleMasterViewModel();
      this.vm.FK_Customer_Acc = new ItemViewModel(0, "");
    } else {
    }
    if (this.vmP == undefined) {
      this.vmP = new DefineAccountviewModel();
      this.customerDetail = [];
      this.selectedcustomerDetail = [];
    } else {
    }
    if (this.modal_title == undefined) {
      this.modal_title = "Sale";
    }
    if (this.vmp == undefined) {
      this.vmp = new GPackagesViewModel();
      this.vm = new G_SaleMasterViewModel();
      this.vm.TransactionDate = new Date();
      this.vmp.SpareParts = [];
      this.vmp.Services = [];
      this.vm.PaymentType = 1;
      this.vm.TransactionDate = new Date();
      this.vm.Fk_G_StatusType_Id = new ItemViewModel(8, "SaleInvoice");
      this.vm.FK_Customer_Acc_Email = new ItemViewModel(0, "");
    } else {
      if (
        this.vm.SalesTagsList !== undefined &&
        this.vm.SalesTagsList.length > 0
      ) {
        this.vm.SalesTagsList.forEach((x) => {
          this.selectedTagsList.push(new ItemViewModel(x.Id, x.Name));
        });
      }

      if (this.vmp.SpareParts !== undefined && this.vmp.SpareParts.length > 0) {
        this.vmp.SpareParts.forEach((x) => {
          this.selectedSpareParts.push(
            new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            )
          );
        });
      }
      if (this.vmp.Services !== undefined && this.vmp.Services.length > 0) {
        this.vmp.Services.forEach((x) => {
          this.selectedServices.push(
            new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            )
          );
        });
      }
    }
    // this.getList();
    // this.getVehicleDetail();
    // this.selectedvehicleDetail();
    // this.saveOrUpdate();
    this.getUserAssingnedWareHouses().then((res) => {
      console.log("promise res", res);
debugger
      this.getSpareParts(this.vehicleDetail[0].FK_G_Model.Id,res[0].FK_WareHouse_Id.Id);
    });
  }

  getUserAssingnedWareHouses() {
    return new Promise((resolve, reject) => {
      this._uow.warehouseService.GetListByUserId().subscribe((res: any) => {
        debugger
        console.log("warehouses", res);
        this.userAssignedWareHouses = res;
        resolve(res);
      });
    });
  }

  getRegNo() {
    this.spinner.show();
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        this.vehicleDetail = res;
        // this.itemVm.RegistraionNumber = res;
        console.log(res);
        this.spinner.hide();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this.spinner.hide();
      }
    );
  }

  getCustomerName() {
    this._uow.customerService.GetList().subscribe(
      (res: any) => {
        
        this.customerDetail = res.DtlList;
       
        // this.itemVm.RegistraionNumber = res;
        this._jq.showSpinner();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this._jq.showSpinner();
      }
    );
  }

  getimage() {
    this._uow.settingservice.GetByImage().subscribe(
      (baseImage: any) => {
        console.log(baseImage);
        if (baseImage != undefined && baseImage !== null) {
          if (
            baseImage.Attachment != undefined &&
            baseImage.Attachment !== null
          ) {
            this.thumbnail =
              "https://fleetdesks.s3.eu-central-1.amazonaws.com/" +
              baseImage.Attachment;
            this.ComName = baseImage.ComName;
            this.Address = baseImage.Address;
            this.contect = baseImage.contect;
          } else {
            this.thumbnail = baseImage.ComName;
            this.ComName = baseImage.ComName;
            this.Address = baseImage.Address;
            this.contect = baseImage.contect;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // onChangeExpense(){
       
  // this.vm.AllowedExpense = this.vm.TotalExpense - this.vm.AllowedExpense;
  // }
  getSupCashList() {
    this.vmP = new DefineAccountviewModel();
    this.vmP.Acc_Titel = this.vmP.Acc_Titel;
    //this.getRegNo();
    this.selectedcustomerDetail = [];
    this.getCustomers();
  }

  getCustomers() {
  
    let Customer = "Customer";
    if (this.vm.PaymentType === 2) {
      Customer = "Cash";
    }
    if (this.vm.PaymentType === 3) {
      Customer = "Office";
    }
  
    console.log(this.selectedcustomerDetail);
    this._uow.customerService.GetListSuplier(Customer).subscribe(
      (res: any) => {
        console.log(res);
        this.customerDetail = res.DtlList;
        if(this.customerDetail.length > 0){
          // // debugger;
          this.customerDetail.forEach(x => {
            x.Acc_Titel = x.Acc_Code + '-' + x.Acc_Titel;
          });
        }
        this.vmP = res.DtlList;
        this.vmP.Acc_Code = res.Acc_Code;
        this.vmP.Acc_Titel = res.Acc_Code + "" + res.Acc_Titel;
        console.log(res);
      },
      (error) => {
        this._uow.logger.log(error);
        this.customerDetail = [];
      }
    );
  }

  public TotalPayable = 0;
  public TotalVat: number;
  public TotalPayableAfterVat = 0;
  calculateTotals() {
    this.TotalPayableAfterVat = 0;
    this.TotalVat = 0;
    this.TotalPayable = 0;
    this.vmp.DiscountAmount = 0;
    this.vmp.PartsAmount = 0;
    if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
      this.vmp.SpareParts.forEach((x) => {
        this.vmp.PartsAmount += x.Amount;
        this.TotalVat += x.VatAmount;
        if (x.DescountAmmount > 0) {
          this.vmp.DiscountAmount += x.DescountAmmount * x.Quantity;
        }
      });
      this.vmp.ServiceAmount = 0;
      this.vmp.Services.forEach((x) => {
        x.VatAmount = x.VatAmount;
        this.vmp.ServiceAmount += x.Amount;
        this.TotalVat += x.VatAmount;
        if (x.DescountAmmount > 0) {
          this.vmp.DiscountAmount += x.DescountAmmount * x.Quantity;
        }
      });
    }
    this.vmp.PackageAmount =
      this.vmp.PartsAmount + this.TotalVat + this.vmp.ServiceAmount;
      // if(this.vm.PaymentType === 3){
        this.totalExpense=  this.vmp.PackageAmount;
        this.allowedExpense = this.vmp.PackageAmount;
        this.vm.AllowedExpense =this.allowedExpense;
        this.vm.TotalExpense =this.totalExpense;
      // }
      
  }

  getRequisitions() {
    console.log(this.selectedvehicleDetail);
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        // console.log(res);
        this.vehicleDetail = res;
      },
      (error) => {
        this._uow.logger.log(error);
        this.vehicleDetail = [];
      }
    );
  }

  onSelectvehicleDetail(e) {
    debugger
    this._uow.logger.log(e);
    console.log('details',e);
    let selectedCustomerList: any = [];
    let selectedWareHouseList: any = [];
   
    let item = this.vehicleDetail.find((x) => x.Id === e.Id);
    
    if (item !== undefined) {
      console.log(item);
      this.itemVm = item;
      this.itemVm.Name = item.Name;
      this.itemVm.RegistraionNumber = item.RegistraionNumber;
      this.itemVm.FK_Tag_List = item.FK_Tag_List;
      this.itemVm.Fk_G_Customer_Id.Id = item.Fk_G_Customer_Id.Id;
      this.itemVm.Fk_G_Customer_Id.Name = item.Fk_G_Customer_Id.Name;
      // this.vm.FK_WareHouse_Id = new ItemViewModel(item.FK_WareHouse_Id.Id,item.FK_WareHouse_Id.Name);
      this.customerAccount = new ItemViewModel(
        item.Fk_G_Customer_Id.Id,
        item.Fk_G_Customer_Id.Name
      );
      this.itemVm.Fk_G_Customer_Discount = item.Fk_G_Customer_Discount;
      if (this.itemVm.Fk_G_Customer_Id.Id != null) {
        let custList = new DefineAccountviewModel();
        custList.Id = this.itemVm.Fk_G_Customer_Id.Id;
        custList.Acc_Titel = this.itemVm.Fk_G_Customer_Id.Name;
        // this.itemVm.Fk_G_Customer_Id.Id =item.Id;
        // this.itemVm.Fk_G_Customer_Id.Name =item.Acc_Titel;
        custList.DiscountValue = this.itemVm.Fk_G_Customer_Discount;
        selectedCustomerList.push(custList);
        console.log(this.itemVm);
        this.selectedcustomerDetail = selectedCustomerList;
      } else {
        this.selectedcustomerDetail = null;
        let cd = document.getElementById("customerdetail");
        cd.classList.add("required");
      }
    }
  }

  onDeSelectvehicleDetail(e) {
    if (this.selectedvehicleDetail.length == 0) {
      this.itemVm = new GVechileViewModel();
      this.itemVm.RegistraionNumber = this.itemVm.RegistraionNumber;
      this.getRegNo();
      this.vmP = new DefineAccountviewModel();
      this.vmP.Id = this.vmP.Id;
      this.vmP.Acc_Titel = this.vmP.Acc_Titel;
      this.selectedcustomerDetail = [];
      this.getCustomerName();
    }
  }

  getVehicleDetail() {
    // 'Spare-Parts'
    this._uow.vehicleService.GetById("Vehicle-Detail").subscribe(
      (res: any) => {
        this.vehicleDetail = res;
        console.log(res);
      },
      (err) => {
        this._uow.logger.log(err);
        this.vehicleDetail = [];
      }
    );
  }
  onChangeAllowedExpense(){
    // if(this.vm.AllowedExpense > this.vm.TotalExpense){
    //   console.log('dd');
    //    return false;
    // }
  }
  onSelectcustomerDetail(e) {
    let selectedCustomerL: any = [];
    this._uow.logger.log(e);
    if (this.vm.PaymentType === 1) {
      let selectedVehicle: any = [];
      let item = this.customerDetail.find((x) => x.Id === e.Id);
      let item2 = this.vehicleDetail.find((y) => y.Id === e.Id);
      if (item !== undefined) {
        this.vmP = item;
        this.vmP.Id = item.Id;
        this.vmP.Acc_Titel = item.Acc_Titel;
        this.vmP.CP_Email = item.CP_Email;
        this.selectedemail.push(item);
        let VehList = new G_SaleMasterViewModel();
        VehList.Fk_G_Customer_Id = new ItemViewModel(0, "");
        VehList.Fk_G_Customer_Id.Id = item.Id;
        VehList.Fk_G_Customer_Id.Name = item.Acc_Titel;
        this.itemVm.Fk_G_Customer_Id.Id =item.Id;
        this.itemVm.Fk_G_Customer_Id.Name =item.Acc_Titel;
        this.customerAccount = new ItemViewModel(item.Id, item.Acc_Titel);
        this.vmP.Id = VehList.Fk_G_Customer_Id.Id;
        this.vmP.Acc_Titel = VehList.Fk_G_Customer_Id.Name;
        selectedCustomerL.push(VehList);
      }
      selectedCustomerL = selectedVehicle = this.selectedvehicleDetail;
      selectedCustomerL = this.selectedcustomerDetail;
      selectedVehicle = this.selectedvehicleDetail;
    }
    else if(this.vm.PaymentType === 3){
      let selectedVehicle: any = [];
      let item = this.customerDetail.find((x) => x.Id === e.Id);
      let item2 = this.vehicleDetail.find((y) => y.Id === e.Id);
      if (item !== undefined) {
        this.vmP = item;
        this.vmP.Id = item.Id;
        this.vmP.Acc_Titel = item.Acc_Titel;
        this.vmP.CP_Email = item.CP_Email;
        this.selectedemail.push(item);
        let VehList = new G_SaleMasterViewModel();
        VehList.Fk_G_Customer_Id = new ItemViewModel(0, "");
        VehList.Fk_G_Customer_Id.Id = item.Id;
        VehList.Fk_G_Customer_Id.Name = item.Acc_Titel;
        this.itemVm.Fk_G_Customer_Id.Id =item.Id;
        this.itemVm.Fk_G_Customer_Id.Name =item.Acc_Titel;
        this.customerAccount = new ItemViewModel(item.Id, item.Acc_Titel);
        this.vmP.Id = VehList.Fk_G_Customer_Id.Id;
        this.vmP.Acc_Titel = VehList.Fk_G_Customer_Id.Name;
        selectedCustomerL.push(VehList);
      }
      selectedCustomerL = selectedVehicle = this.selectedvehicleDetail;
      selectedCustomerL = this.selectedcustomerDetail;
      selectedVehicle = this.selectedvehicleDetail;
    } 
    else {
      let item = this.customerDetail.find((x) => x.Id === e.Id);
      let item2 = this.vehicleDetail.find((y) => y.Id === e.Id);
      let VehList = new G_SaleMasterViewModel();
      VehList.Fk_G_Customer_Id = new ItemViewModel(0, "");
      VehList.Fk_G_Customer_Id.Id = e.Id;
      VehList.Fk_G_Customer_Id.Name = e.Acc_Titel;
      this.vmP.Id = VehList.Fk_G_Customer_Id.Id;
      this.vmP.Acc_Titel = VehList.Fk_G_Customer_Id.Name;
      this.vm.Fk_G_Customer_Id.Id = e.Id;
      this.vm.Fk_G_Customer_Id.Name = item.Acc_Titel;
      this.itemVm.Fk_G_Customer_Id.Id =item.Id;
        this.itemVm.Fk_G_Customer_Id.Name =e.Acc_Titel;
      this.customerAccount = new ItemViewModel(e.Id, e.Acc_Titel);
      selectedCustomerL.push(VehList);
    }
  }

  onDeSelectcustomerDetail(e) {
    if (this.selectedcustomerDetail.length == 0) {
      this.vmP = new DefineAccountviewModel();
      this.vmP.Acc_Titel = this.vmP.Acc_Titel;
      this.getRegNo();
    }
  }

  getCustomerDetail() {
    // 'Spare-Parts'
    this._jq.showSpinner(true);
    this._uow.customerService.GetById("Customer-Detail").subscribe(
      (res: any) => {
        this.customerDetail = res;
        console.log(res);
        this._jq.showSpinner();
      },
      (err) => {
        this._uow.logger.log(err);
        this.customerDetail = [];
        this._jq.showSpinner();
      }
    );
  }

  quantityChange(e, i?) {
    //e.DiscountAmount = 0;
    e.Amount = e.Quantity * e.DiscountAmount;
    let saledetail = this.vm.SaleDetails.find(
      (x) => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (saledetail != undefined) {
      saledetail.Quantity = e.Quantity;
      saledetail.TotalAmount = e.Amount;
    }
    //  if (e.DescountPercentage > 0) {
    //    e.Amount = (e.Amount - ((e.DescountPercentage / 100) * e.Amount));
    //  }
    //this.calculateTotals();
    this.vatChanged();
    this.InventoryCheckAll();
  }

  discountChange(e, i?) {
    let discountAMmount = (e.DescountPercentage * e.SalePrice) / 100;
    e.DescountAmmount = discountAMmount;
    e.DiscountAmount = e.SalePrice - e.DescountAmmount;
    e.Amount = e.DiscountAmount * e.Quantity;
    let saledtl = this.vm.SaleDetails.find(
      (x) => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (saledtl != undefined) {
      saledtl.Rate = e.SalePrice;
      saledtl.DiscountAmount = e.DescountAmmount;
      saledtl.Discount = e.DescountPercentage;
      saledtl.DiscountedRate = e.DiscountAmount;
      saledtl.TotalAmount = e.Amount;
    }
    // if(this.vmP.DiscountAmount > 0){
    //
    //   this.calculateTotals();
    // }
    // if(this.vmP.DiscountAmount <= 0){
    //   let discountAMmount = ((e.DescountPercentage / 100) * e.Amount);
    //   e.Amount = (e.Amount - discountAMmount);
    //   this.calculateTotals();
    // }
    this.vatChanged();
    //this.calculateTotals();
  }

  amountChange(e, i?) {
    e.DescountPercentage = (e.DescountAmmount * 100) / e.SalePrice;
    this.discountChange(e, i);
    this.vatChanged();
    //this.calculateTotals();
    // if(this.vmP.DiscountAmount > 0){
    //
    //   this.calculateTotals();
    // }
    // if(this.vmP.DiscountAmount < 0){
    //   let discountAMmount = (e.Amount-e.DescountAmmount);
    //   e.Amount = (discountAMmount);

    // }
  }

  vatChanged() {
  
    if (this.vmDetail > -1) {
      if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
        this.vm.SaleDetails.forEach((x) => {
          x.VAT_Value = this.vmDetail;
          x.VatAmount = (x.TotalAmount / 100) * this.vmDetail;
          // x.VatAmount =  (x.VatAmount.toFixed(2);
        });
      }
      if (this.vmp.SpareParts !== undefined && this.vmp.SpareParts.length > 0) {
        this.vmp.SpareParts.forEach((x) => {
          x.VatAmount = (x.Amount / 100) * this.vmDetail;
          x.TotalAmount = x.Amount + x.VatAmount;
          // x.VatAmount.toPrecision(2);
          // x.VatAmount= x.VatAmount.toFixed(2);
          console.log(x.VatAmount);
        });
      }

      if (this.vmp.Services !== undefined && this.vmp.Services.length > 0) {
        this.vmp.Services.forEach((x) => {
          x.VatAmount = (x.Amount / 100) * this.vmDetail;
          x.TotalAmount = x.Amount + x.VatAmount;
          // x.VatAmount =  x.VatAmount.toFixed(2);
          // x.VatAmount.toFixed(2);
        });
      }
      this.calculateTotals();
    }
  }

  vatAmountChanged(i) {
    this.calculateTotals();
  }

  getSortIndexList(sIndex) {
    this._jq.showSpinner(true);
    this._uow.vehicleService.GetPagedResult(0, 50, sIndex).subscribe(
      (res: any) => {
        this.vmListItems = res.DtlList;
        console.log(res);

        this._jq.showSpinner();
      },
      (err) => {
        console.log(err);
        this._jq.showSpinner();
      }
    );
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new GVechileViewModel();
    }
  }

  onSelectTags(e) {
    if (
      this.vm.SalesTagsList == undefined ||
      this.vm.SalesTagsList.length == 0
    ) {
      this.vm.SalesTagsList = [];
    }
    this._uow.logger.log(e);
    let item = this.tags.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let i = new G_TagsViewModel();
      i.Name = item.Name;
      i.FK_Tag_Id = item.Id;
      this.vm.SalesTagsList.push(i);
      // let a = new G_SaleMasterViewModel();
      // a.Id = item.Id;
      // a.Name = item.Name;
      // this.vm.SalesTags.push(i);
    }
  }

  onDeSelectTags(e) {
    let i = this.vm.SalesTagsList.find((x) => x.FK_Tag_Id === e.Id);
    if (i !== undefined) {
      this.vm.SalesTagsList.splice(this.vm.SalesTagsList.indexOf(i), 1);
    }
  }
  // spare parts
  onSelectSparePart(e) {
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    this._uow.logger.log(e);
    let item = this.spareParts.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.DiscountedRate = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      i.Discount = 0;
      i.DiscountAmount = 0;
      this.vm.SaleDetails.push(i);
      let a = new GPackageItemViewModel();
      a.Id = 0;
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      a.DiscountAmount = item.SalePrice;
      if (this.itemVm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
      }
      this.vmp.SpareParts.push(a);
      this.discountChange(this.vmp.SpareParts[this.vmp.SpareParts.indexOf(a)]);
      //this.selectedSpareParts.push(a);
    }
    this.vatChanged();
    this.calculateTotals();
    this.InventoryCheck(e.Id);
  }

  onDeSelectSparePart(e) {
    debugger;
    let i = this.vmp.SpareParts.find(
      (x) => x.Fk_G_SSItemDefining_Id.Id === e.Id
    );
    if (i !== undefined) {
      this.vmp.SpareParts.splice(this.vmp.SpareParts.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find((x) => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    // let a = this.vm.SpareParts.find(x=> x.FK_G_Item_Id.Id === e.Id);
    //   if(a !== undefined){
    //     this.vm.SpareParts.splice(this.vm.SpareParts.indexOf(a),1);
    //   }

    this.calculateTotals();
    this.InventoryCheckAll();
  }

  //service
  onSelectService(e) {
    debugger;
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    if (
      this.SelectedItemServices == undefined ||
      this.SelectedItemServices.length == 0
    ) {
      this.SelectedItemServices = [];
    }
    this._uow.logger.log(e);
    let item = this.services.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.DiscountedRate = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      i.Discount = 0;
      i.DiscountAmount = 0;
      this.vm.SaleDetails.push(i);
      let a = new GPackageItemViewModel();
      a.Id = 0;
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      a.DiscountAmount = item.SalePrice;
      if (this.itemVm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
      }
      this.vmp.Services.push(a);
      this.discountChange(this.vmp.Services[this.vmp.Services.indexOf(a)]);
      this.SelectedItemServices.push(item);
    }
    this.vatChanged();
    this.calculateTotals();
  }

  onDeSelectService(e) {
    let i = this.vmp.Services.find((x) => x.Fk_G_SSItemDefining_Id.Id === e.Id);
    if (i !== undefined) {
      this.vmp.Services.splice(this.vmp.Services.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find((x) => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    this.calculateTotals();
  }

  getServices() {
    // 'Services'
    this._jq.showSpinner(true);
    this._uow.itemService.GetSSItemDefiningByItemType("Services").subscribe(
      (res: any) => {
        this.services = res;
        console.log(res);
        this._jq.showSpinner();
        // this._uow.logger.log(`services ${res}`);
      },
      (err) => {
        this._uow.logger.log(err);
        this.services = [];
        this._jq.showSpinner();
      }
    );
  }

  //'Spare-Parts'
  getSpareParts(id, idd?) {
    debugger;
    let selectedSpare: any = [];
    this._uow.itemService
      .GetSSItemDefiningByItemTypeByModel(id, idd)
      .subscribe(
        (res: any) => {
          debugger
          this.spareParts = res;
          this.InventoryCheckAll();
          console.log("spare parts", res);
          if (this.selectedSpareParts.length > 0) {
            console.log(this.selectedSpareParts);
            this.selectedSpareParts.forEach((x) => {
              console.log(this.vmp.SpareParts);
              
              let selected = this.vmp.SpareParts.find((z) => z.Fk_G_SSItemDefining_Id.Id === x.Id);
              console.log("selcetd part", selected);
              let md = new ItemViewModel();
              md.Id = selected.Fk_G_SSItemDefining_Id.Id,
                md.Name = selected.Fk_G_SSItemDefining_Id.Name
              selectedSpare.push(md);
              this.selectedSpareParts = selectedSpare;
            });
          }
        },
        (err) => {
          this._uow.logger.log(err);
          this.spareParts = [];
        }
      );
  }

  getTags() {
    this._uow.tagsService.GetList().subscribe(
      (res: any) => {
        this.tags = res;
      },
      (err) => console.log(err)
    );
  }

  saveOrUpdate() {
    debugger;
    // this._jq.showSpinner(true);
    this._uow.vehicleService
      .Post(this.itemVm)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.onSubmitted.emit(this.submitted);
      })
      .catch((error) => {
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(false);
        this._jq.showSpinner();
      });

    this._jq.showSpinner(true);
    let obj = this.itemVm;
    //  binding vm object by itemvm
    this.vm.DriverName = this.itemVm.DriverName;
    this.vm.DriverPhone = this.itemVm.DriverPhone;
    this.vm.RefId = "test";
    this.vm.VatValue = this.vmDetail;
    this.vm.Inv = 1234;
    this.vm.Validity = 1;
    this.vm.FK_Model = new ItemViewModel();
    this.vm.FK_Model.Id = this.itemVm.FK_G_Model.Id;
    this.vm.FK_Model.Name = this.itemVm.FK_G_Model.Name;
    this.vm.FK_Brand = new ItemViewModel();
    this.vm.FK_Brand.Id = this.itemVm.Fk_G_Brand.Id;
    this.vm.FK_Brand.Name = this.itemVm.Fk_G_Brand.Name;
    this.vm.Fk_G_Vechile_Id = new ItemViewModel();
    this.vm.Fk_G_Vechile_Id.Id = this.itemVm.Id;
    this.vm.Fk_G_Vechile_Id.Name = this.itemVm.Name;
    this.vm.RegistraionNumber = this.itemVm.RegistraionNumber;
    this.vm.Fk_G_Customer_Id = new ItemViewModel();
    this.vm.Fk_G_Customer_Id.Name = this.itemVm.Fk_G_Customer_Id.Name;
    this.vm.Fk_G_Customer_Id.Id = this.itemVm.Fk_G_Customer_Id.Id;
    this.vm.FK_Customer_Acc = new ItemViewModel();
    this.vm.FK_Customer_Acc.Id = this.customerAccount.Id;
    this.vm.FK_Customer_Acc.Name = this.customerAccount.Name;
    if (this.vm.PaymentType == 1) {
      this.onChange(true);
    }
    this.vm.FK_Cradit_Acc = new ItemViewModel();
    this.vm.FK_Cradit_Acc.Id = 0;
    this.vm.FK_Cradit_Acc.Name = "";
    this.vm.Fk_G_StatusType_Id = new ItemViewModel(8, "SaleInvoice");
    // this.vm.Fk_G_Tags_Id = new ItemViewModel();
    // this.vm.Fk_G_Tags_Id .Id = 0;
    // this.vm.Fk_G_Tags_Id.Name = "";
    this.vm.Fk_G_SPSType_Id = new ItemViewModel();
    this.vm.Fk_G_SPSType_Id.Id = 0;
    this.vm.Fk_G_SPSType_Id.Name = "";
    this.vm.Remarks = "";
    // this.vm.PaymentType = 1;
    this.vm.Staus = "";
    this.vm.Inv_Amount = this.vmp.PackageAmount;
    this.vm.PaidAmount = this.vm.Inv_Amount;
    this.vm.PartsAmount = this.vmp.PartsAmount;
    this.vm.ServiceAmount = this.vmp.ServiceAmount;
    this.vm.IsPaymentDone = false;
    this.vm.IsCredit = false;
    this.vm.IsCounterSale = true;
    this.vm.Odometer = this.itemVm.Odometer;
    this.vm.Attachment = "";
    this.vm.FK_ClientId = new ItemViewModel();
    this.vm.FK_ClientId.Id = 1;
    this.vm.FK_ClientId.Name = "";
    // let a = new GPackagesViewModel();
    // this.vm.PackageDetail = [];
    // //a.Fk_PackageType_Id.Id = this.vmP.Id;
    // //a.Fk_PackageType_Id.Name = this.vmP.Name;
    // a.Id = this.vmP.Id;
    // a.Name = this.vmP.Name;
    // a.PartsAmount = this.vmP.PartsAmount;
    // a.ServiceAmount = this.vmP.ServiceAmount;
    // a.PackageAmount = this.vmP.PackageAmount;
    // a.TotalAmount = this.vmP.TotalAmount;
    // a.Discount = this.vmP.Discount;
    // a.DiscountAmount = this.vmP.DiscountAmount
    // this.vm.PackageDetail.push(this.packageTypes);
    //  let i = new G_ComplainsSuggestionsViewModel();
    //  this.vm.CustomerComplains = [];
    //  i.fk = this.vmC.FK_G_Complains_Id.Id;
    //  i.Name = this.vmC.Name;
    //i.FK_G_Complains_Id = this.vmC.FK_G_Complains_Id,
    //i.ComplainsType = this.vmC.ComplainsType;
    // this.vm.CustomerComplains.push(this.complaintsType);
    // this.vm.Findings =[];
    // this.complaintsType.Id = this.vmC.Id;
    // this.complaintsType.Name = this.vmC.Name;
    // this.vm.Findings.push(this.complaintsType);
    this.vm.AttachmentList = "";
    this.vm.CreatedBy = "";

    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.saleService
      .Post(this.vm)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        this.vm = res;
        this.submitted = true;
        this.onSubmitted.emit(this.submitted);
        //this.bsmRef.hide();
        this.vm = res;
        this.EnableNext = true;
        this.modalRef.hide();
        this.spinner.hide();
        Swal.fire("Saved!", "Direct Sale Created!", "success");
        // this._jq.showSpinner();
        this._router.navigate(["/direct-sale-list"]);
      })
      .catch((error) => {
        Swal.fire("Error!", "Direct Sale Not Saved!", "error");
        this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(false);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
  }

  // saveOrUpdate() {
  //   this._jq.showSpinner(true);
  //   this._uow.vehicleService.Post(this.vm).subscribe((res: any) => {
  //     this.getList();
  //     this.modalRefLv1.hide();
  //   }, (err) => {
  //     this._jq.showSpinner();
  //   });
  // }

  OpenCreateDirectInvoice(e) {
    this._router.navigate(["/direct-sale-invoice", { id: e }]);
  }

  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  InventoryCheck(e) {
    
    let SparepartsEvent = this.spareParts.find((y) => y.Id === e);
    if (SparepartsEvent != undefined && SparepartsEvent != null) {
      let AlreadySelectedParts = this.vmp.SpareParts.find(
        (y) => y.Fk_G_SSItemDefining_Id.Id === e
      );
      if (AlreadySelectedParts != undefined && AlreadySelectedParts != null) {
        let AlreadyPartsIndex = this.vmp.SpareParts.indexOf(
          AlreadySelectedParts
        );
        if (AlreadySelectedParts.Quantity > SparepartsEvent.CurrentStock) {
          if (AlreadyPartsIndex != undefined) {
            this.vmp.SpareParts[AlreadyPartsIndex].IsInventry = false;
          }
          //this.DisableSave = true;
        } else {
          if (AlreadyPartsIndex != undefined) {
            this.vmp.SpareParts[AlreadyPartsIndex].IsInventry = true;
          }
          AlreadySelectedParts.IsInventry = true;
          //this.DisableSave = false;
        }
      }
    }
    this.InventoryCheckAll();
  }

  InventoryCheckAll() {
    
    if (this.vmp.SpareParts != undefined && this.vmp.SpareParts.length > 0) {
      this.vmp.SpareParts.forEach((x) => {
        let SparepartsEvent = this.spareParts.find(
          (y) => y.Id === x.Fk_G_SSItemDefining_Id.Id
        );
        if (SparepartsEvent != undefined && SparepartsEvent != null) {
          if (x.Quantity > SparepartsEvent.CurrentStock) {
            x.IsInventry = false;
            //this.DisableSave = true;
          } else {
            x.IsInventry = true;
            //this.DisableSave = false;
          }
        }
      });
      let DisableSave = this.vmp.SpareParts.find(x=> x.IsInventry === false);
      if (DisableSave) {
        this.DisableSave = true;
      }else{
        this.DisableSave = false;
      }
    } else {
      this.DisableSave = false;
    }
    this.DisableSave = false;
  }
  onSelectWareHouse() {
    console.log(this.vm.Fk_G_WareHouse_Id);
debugger
    this.getSpareParts(this.itemVm.FK_G_Model.Id, this.vm.Fk_G_WareHouse_Id.Id);
  }
  openModalLg(template: TemplateRef<any>, itemtype?) {
    this.ssItemVm = new GItemDefiningViewModel();
    if (itemtype == 1) {
      let i = this.itemTypes.find((x) => x.Name == "Spare-Parts");
      console.log(i);
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    if (itemtype == 2) {
      let i = this.itemTypes.find((x) => x.Name == "Services");
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    this.openModal(template);
    this.modalRef.setClass("modal-lg");
  }

  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
      this.modalRef.setClass("modal-lg");
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
    if (lvl == 3) {
      this.modalRefLv3 = this._modalService.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }

  conformDelete() {
    this.spinner.show();
    this._uow.vehicleService.Delete(this.itemVm).subscribe(
      (res: any) => {
        this.getList();
        this.modalRefLv1.hide();
        this.spinner.hide();
      },
      (err) => {
        // this._jq.showSpinner();
        this.spinner.hide();
      }
    );
  }

  // external components
  onItemSubmitted(e) {
    if (e == true) {
      this.getList();
      this.getTags();
      this._jq.showSpinner(false);
    }
  }

  getList() {
    this._jq.showSpinner(true);
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        this.vmListItems = res.DtlList;
        console.log(res);
        this._jq.showSpinner();
      },
      (err) => {
        console.log(err);
        this._jq.showSpinner();
      }
    );
  }

  navigateClick() {
    this.isPreview = false;
    this.modalRef.hide();
  }

  openPreview(content) {
   debugger
   this.tagsJoin = this.selectedTagsList.map(tag => tag.Name).join(", ");
    this.modalRef = this._uow.modalServiceEx.show(content);
    this.modalRef.setClass("modal-lg");
    this.isPreview = true;
  }
  public amountCheck:boolean=false;
  onEnterAmount(){
    if(this.vm.AllowedExpense > this.vm.TotalExpense ){
      this.amountCheck = true;
       return;
    }
    if(this.vm.AllowedExpense <= this.vm.TotalExpense){
      this.amountCheck = false;
    }
  }
}
