import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { InventryFlowReport } from 'src/app/viewmodels/g-inventory/inventory-flow';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-inventory-flow-report',
  templateUrl: './inventory-flow-report.component.html',
  styleUrls: ['./inventory-flow-report.component.scss']
})
export class InventoryFlowReportComponent implements OnInit {
public InventryFlowReport =new InventryFlowReport();
public vmListItems: Array<any> = [];
public vmListWarehouses: any = [];
FK_WareHouse_Id = new ItemViewModel();
selectedSpareParts : Array<any> =[];
inventoryFlowList:Array<InventryFlowReport> =[];
InCount:number =0;
OutCount: number = 0;
ListCount: number =0; 
fromDate: any = null;
  toDate : any = null;
public itemsDropdownSettings: IDropdownSettings = {
  singleSelection: true,
  idField: 'Id',
  textField: 'Name',
  enableCheckAll: false,
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3,
  allowSearchFilter: true,
  clearSearchFilter:true,
  closeDropDownOnSelection: true
};
  constructor(private _uow: UnitOfWorkService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    // this.getSpareParts();
    this.getWarehouse();
  }
  getWarehouse(){
    this._uow.warehouseService.GetListByUserId().subscribe((res:any) =>{
      console.log('res',res);
      this.vmListWarehouses = res;
    });
  }
  getSpareParts() {
  if(this.FK_WareHouse_Id !== undefined){
    this._uow.wareHouseItemService.GetListItemByWare(this.FK_WareHouse_Id.Id).subscribe(
      (res: any) => {
        this.vmListItems = res;
        if(this.vmListItems.length > 0){
         this.vmListItems.forEach(x =>{
           x.Name = x.Code +'-'+ x.Name;
         });
        }
        console.log(res);
      },
      (err) => {
        this._uow.logger.log(err);
        this.vmListItems = [];
      }
    );
  }
  }
  onItemDeSelect(event){
    debugger;
    this.selectedSpareParts =[];
  }
  fetchInventoryFlowReport(){
    console.log('ware',this.FK_WareHouse_Id);
    console.log('item',this.selectedSpareParts);
    debugger;
    const raw ={
      itemId:this.selectedSpareParts[0].Id,
      wareHouseId:this.FK_WareHouse_Id.Id
    };
    this.spinner.show();
    this._uow.itemService.GetG_InventoryFlowReport(raw).subscribe((res:any) =>{
      console.log('report response',res);
      debugger
      this.inventoryFlowList = res;
      if(this.inventoryFlowList.length != 0){
        this.InCount = this.inventoryFlowList[0].StockInCount;
        this.OutCount = this.inventoryFlowList[0].StockOutCount;
      }
      this.ListCount = this.inventoryFlowList.length;
      
      this.spinner.hide();
    },error =>{
      console.log(error);
      this.spinner.hide();
    });
  }


  exportToPdf() {
    this.spinner.show();
    debugger;
    // Assuming `toDate` is a JavaScript Date object
    const today = new Date(this.toDate); // Ensure this.toDate is a valid date

    // Format the date as MM-DD-YYYY
    const formattedDate = `${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}-${today.getFullYear()}`;

    const element = document.getElementById('IFReport');

    html2canvas(element, {
      scale: 2,
      useCORS: true,
      allowTaint: true
  }).then((canvas) => {
      canvas.toBlob((blob) => {
          const imgData = URL.createObjectURL(blob);
          const pdf = new jsPDF('p', 'mm', 'a4');
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;
          
          const ratio = pdfWidth / canvasWidth;
          const pdfCanvasHeight = canvasHeight * ratio;
          
          let position = 0;
          let pageHeight = pdfHeight;
          let heightLeft = pdfCanvasHeight;
          
          while (heightLeft > 0) {
              if (position !== 0) {
                  pdf.addPage();
              }
  
              pdf.addImage(
                  imgData,
                  'PNG',
                  0,
                  position - (position > 0 ? 5 : 0),
                  pdfWidth,
                  pdfCanvasHeight
              );
              heightLeft -= pageHeight;
              position -= pageHeight;
          }
          debugger
          var filename = `IFReport_${this.selectedSpareParts[0].Name}_${formattedDate}.pdf`;
          pdf.save(filename);
      }, 'image/jpeg');
  });
    this.spinner.hide();
}
}
